import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Common/Layout/Layout';
import Seo from '../components/Seo';
import Hero from '../components/Common/Hero/Hero';
import MaintenanceForm from '../components/MaintenanceForm/MaintenanceForm';

const MaintenanceRequestPage = () => (
  <Layout>
    <Seo
      title="Maintenance Request Form"
      description="Current residents should complete and submit the online maintenance request form, in order to report an issue or request maintenance."
    />
    <Hero
      extend
      background={
        <StaticImage
          src="../images/large-hero-bg.jpg"
          layout="fullWidth"
          placeholder="blurred"
          quality="90"
          alt=""
        />
      }
    >
      <h1 className="h-page-hero-title">Maintenance Request</h1>
    </Hero>
    <MaintenanceForm />
  </Layout>
);

export default MaintenanceRequestPage;
