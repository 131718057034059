import React from 'react';

import './MaintenanceForm.css';

import Form from '../Common/Form/Form';
import Input from '../Common/Input/Input';
import BuildingSelector from '../Common/BuildingSelector/BuildingSelector';
import Select from '../Common/Input/Select';
import TextArea from '../Common/Input/TextArea';
import Button from '../Common/Button/Button';
import Checkbox from '../Common/Checkbox/Checkbox';

const MaintenanceForm = () => (
  <div className="l-container l-container--sm l-container--no-mobile-gutter">
    <section className="c-maintenance-form">
      <Form
        className="w-full"
        formName="maintenance-request"
        successTitle="Maintenance request sent"
        successMessage="Thank you for your maintenance request. We will get back to you as soon as possible."
        errorTitle="Maintenance request not sent"
        errorMessage="Sorry your maintenance request has not been sent. Please try again."
      >
        <input type="hidden" name="form-name" value="maintenance-request" />
        <Input
          horizontal
          className="mb-5"
          label="Full Name"
          type="text"
          name="FullName"
          placeholder="Enter your full name…"
        />
        <Input
          horizontal
          className="mb-5"
          label="Email"
          type="email"
          name="Email"
          placeholder="Enter your email address…"
        />
        <Input
          horizontal
          optionalLabel
          className="mb-5"
          label="Phone"
          type="tel"
          name="Phone"
          placeholder="Enter your phone number…"
        />
        <BuildingSelector />
        <Input
          horizontal
          className="mb-5"
          label="Unit #"
          type="text"
          name="UnitNumber"
          placeholder="Enter unit number…"
        />
        <Select
          horizontal
          className="mb-5"
          label="Category"
          name="Category"
          placeholder="Select category"
        >
          <option value="Electrical">Electrical</option>
          <option value="Heating">Heating</option>
          <option value="Plumbing">Plumbing</option>
          <option value="Appliances">Appliances</option>
          <option value="Security">Security</option>
          <option value="Other">Other</option>
        </Select>
        <TextArea
          horizontal
          label="Description"
          name="Description"
          placeholder="Enter a description of your maintenance request…"
        />
        <div className="c-maintenance-form__action">
          <div />
          <div>
            <Checkbox
              name="consent"
              label="For the purpose of completing the specified work, I hereby authorize maintenance to enter my unit in my absence"
            />
            <Button
              className="mt-8"
              submit
              label="Submit Maintenance Request"
            />
          </div>
        </div>
      </Form>
    </section>
  </div>
);

export default MaintenanceForm;
